import React from 'react';
import { graphql } from 'gatsby';

import SubLayout from '../components/SubLayout';
import { Img } from '../components/common/Img.style';
import ArticleTemplate from '../components/ArticleTemplate';

import res1lg from '../assets/img/res-1-lg.png';

const RespiratoryTemplate = ({
  data, // this prop will be injected by the GraphQL query below.
  pageContext,
}) => {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const { title, date } = frontmatter;
  const { previous, next } = pageContext;
  return (
    <SubLayout topTitle="呼吸器内科のその他の記事">
      <Img src={res1lg} />
      <ArticleTemplate
        topLink="/respiratory"
        date={date}
        title={title}
        previous={previous}
        next={next}
        html={html}
      />
    </SubLayout>
  );
};

export default RespiratoryTemplate;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "YYYY/MM/DD")
        title
      }
    }
  }
`;
